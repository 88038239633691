import React from "react"
import { injectIntl } from 'gatsby-plugin-intl';
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title="404: Not found" />

    <div className="main">
      <div className="block standard not-found">
        <div className="container-fluid">
          <div className="row">
            <div className="col col-xl-6">
              <h1>
                  <span>{intl.formatMessage({ id: "error_code" })}</span>
                  {" "}{intl.formatMessage({ id: "error_header" })}
              </h1>
              <p>{intl.formatMessage({ id: "error_body" })}</p>
              <a
                  className="button secondary"
                  title={intl.formatMessage({ id: 'home_nav_1' })}
                  href="/"
              >
                {intl.formatMessage({ id: 'home_nav_1' })}
              </a>
              <a className="button secondary" title={intl.formatMessage({ id: "footer_contact" })} target="_blank" rel="noreferrer" href="https://www.fisglobal.com/en/contact-us">
                  {intl.formatMessage({ id: "footer_contact" })}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default injectIntl(NotFoundPage);
